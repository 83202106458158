/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import Input from '../../Input';
import Select from '../../Select';

import productImageAlt from '../../../assets/productImageAlt.svg';
import add_photo from '../../../assets/add_photo.svg';
import save from '../../../assets/save.svg';
import chevron from '../../../assets/chevron.svg';
import loading from '../../../assets/loading03.svg';

import {
  Container,
  Widget,
  Icon,
  Form,
  FormLine,
  FormLineBottom,
  ButtonBox,
  Button,
} from './styles';

import { Product, Category } from '../../../types/Product';
import Table from '../../Table';
import Th from '../../Table/Th';
import Td from '../../Table/Td';
import CheckBox from '../../CheckBox';

const schema = yup.object().shape({
  name: yup.string().required('* o nome é obrigatório'),
  description: yup.string().required('* a descrição é obrigatória'),
  marketCategoryId: yup.string().required('* a categoria é obrigatória'),
  stock: yup.number().moreThan(-1).required('* o stock é obrigatório'),
  stockAlert: yup
    .number()
    .moreThan(-1)
    .required('* o alerta de stock é obrigatório'),
  stockZeroBlock: yup.boolean().required('o produto esgora é obrigatório'),
  value: yup.number().moreThan(-1).required('* o valor é obrigatório'),
  visibleTo: yup.string().required('* o visível para é obrigatória'),
  visibleOnTicket: yup
    .boolean()
    .required('o visível na etiqueta é obrigatório'),
  member1: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member2: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member3: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member4: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member5: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member6: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member7: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member8: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member9: yup.number().moreThan(-1).required('* o limite obrigatório'),
  member10: yup.number().moreThan(-1).required('* o limite obrigatório'),
  memberMonth1: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth2: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth3: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth4: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth5: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth6: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth7: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth8: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth9: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  memberMonth10: yup
    .number()
    .moreThan(-1)
    .required('* o limite mês obrigatório'),
  catShelf: yup
    .number()
    .moreThan(-1)
    .required('* a categoria de prateleira obrigatória'),
});

type ProductFormProps = {
  product: Product | null;
  iconUrl: string | null;
  closeModal(): void;
  handleUpdateProduct(id: string, status: 'update' | 'add'): void;
  visible: boolean;
};

type ProductLimitPropsForm = {
  member1: number;
  member2: number;
  member3: number;
  member4: number;
  member5: number;
  member6: number;
  member7: number;
  member8: number;
  member9: number;
  member10: number;
};

type ProductLimitMonthPropsForm = {
  memberMonth1: number;
  memberMonth2: number;
  memberMonth3: number;
  memberMonth4: number;
  memberMonth5: number;
  memberMonth6: number;
  memberMonth7: number;
  memberMonth8: number;
  memberMonth9: number;
  memberMonth10: number;
};

const ProductForm: React.FC<ProductFormProps> = ({
  product,
  iconUrl,
  closeModal,
  handleUpdateProduct,
  visible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([] as Category[]);
  const [icon, setIcon] = useState<File | null>(null);
  const [iconError, setIconError] = useState('');
  const [iconDisplay, setIconDisplay] = useState(productImageAlt);

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm<
    Omit<Product, 'id' | 'createdAt' | 'updatedAt' | 'deleted'> &
      ProductLimitPropsForm &
      ProductLimitMonthPropsForm
  >({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const handleChangeIcon = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.currentTarget.files) {
      const imageSelected = e.currentTarget.files[0];

      if (imageSelected.size > 2097152) {
        setIconError('* image maior que 2MB');
        return;
      }

      if (
        imageSelected.type !== 'image/jpeg' &&
        imageSelected.type !== 'image/png'
      ) {
        setIconError(
          '* formato da imagem não suportado, a imagem tem de ser .jpeg ou .png',
        );
        return;
      }
      setIconError('');
      setIcon(imageSelected);
      setIconDisplay(URL.createObjectURL(imageSelected));
    }
  };

  const handleResetForm = useCallback(() => {
    reset({
      name: '',
      description: '',
      marketCategoryId: '',
      stock: 0,
      stockAlert: 0,
      value: 0,
      member1: 0,
      member2: 0,
      member3: 0,
      member4: 0,
      member5: 0,
      member6: 0,
      member7: 0,
      member8: 0,
      member9: 0,
      member10: 0,
      memberMonth1: 0,
      memberMonth2: 0,
      memberMonth3: 0,
      memberMonth4: 0,
      memberMonth5: 0,
      memberMonth6: 0,
      memberMonth7: 0,
      memberMonth8: 0,
      memberMonth9: 0,
      memberMonth10: 0,
      catShelf: 0,
    });
    setIconDisplay(productImageAlt);
    setIcon(null);
    closeModal();
  }, [reset, closeModal]);

  const handleOnSubmit = async (
    data: Omit<Product, 'id' | 'createdAt' | 'updatedAt' | 'deleted'> &
      ProductLimitPropsForm,
  ) => {
    setIsLoading(true);
    try {
      if (product) {
        const response = await api.put(`market/product/${product.id}`, {
          ...data,
        });
        if (response.data.id && icon) {
          const iconFormData = new FormData();
          iconFormData.append('productIcon', icon);
          await api.put(
            `market/product/icon/${response.data.id}`,
            iconFormData,
          );
        }
        handleUpdateProduct(response.data.id, 'update');
      } else {
        const response = await api.post('market/product', {
          ...data,
          availableOnMarket: true,
        });
        if (response.data.id && icon) {
          const iconFormData = new FormData();
          iconFormData.append('productIcon', icon);
          await api.post(
            `market/product/icon/${response.data.id}`,
            iconFormData,
          );
        }
        handleUpdateProduct(response.data.id, 'add');
      }
      addToast({
        type: 'success',
        title: product ? 'Edição de Produto' : 'Criação de Produto',
        description: { code: product ? 509 : 508 },
      });
      closeModal();
      setIsLoading(false);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: product
          ? 'Erro na edição do produto'
          : 'Erro na criação do produto',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get('/market/category');
      setCategories(response.data);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter categorias',
        description: { code: 506 },
      });
    }
  }, [addToast]);

  const loadProductData = useCallback(
    async (p: Product | null) => {
      if (p) {
        setValue('name', p.name);
        setValue('description', p.description);
        setValue('marketCategoryId', p.marketCategoryId);
        setValue('stock', p.stock);
        setValue('stockAlert', p.stockAlert);
        setValue('value', p.value);
        setValue('visibleTo', p.visibleTo);
        setValue('stockZeroBlock', p.stockZeroBlock);
        setValue('visibleOnTicket', p.visibleOnTicket);
        setValue('catShelf', p.catShelf);
        if (p.limits) {
          setValue('member1', p.limits.member1);
          setValue('member2', p.limits.member2);
          setValue('member3', p.limits.member3);
          setValue('member4', p.limits.member4);
          setValue('member5', p.limits.member5);
          setValue('member6', p.limits.member6);
          setValue('member7', p.limits.member7);
          setValue('member8', p.limits.member8);
          setValue('member9', p.limits.member9);
          setValue('member10', p.limits.member10);
        }
        if (p.limitsMonth) {
          setValue('memberMonth1', p.limitsMonth.memberMonth1);
          setValue('memberMonth2', p.limitsMonth.memberMonth2);
          setValue('memberMonth3', p.limitsMonth.memberMonth3);
          setValue('memberMonth4', p.limitsMonth.memberMonth4);
          setValue('memberMonth5', p.limitsMonth.memberMonth5);
          setValue('memberMonth6', p.limitsMonth.memberMonth6);
          setValue('memberMonth7', p.limitsMonth.memberMonth7);
          setValue('memberMonth8', p.limitsMonth.memberMonth8);
          setValue('memberMonth9', p.limitsMonth.memberMonth9);
          setValue('memberMonth10', p.limitsMonth.memberMonth10);
        }
        if (p.icon && iconUrl) {
          setIconDisplay(iconUrl);
        }
      }
    },
    [setValue, iconUrl],
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (visible) {
      loadProductData(product);
    } else {
      handleResetForm();
    }
  }, [visible]);

  return (
    <Container>
      {product ? <h1>Editar Produto</h1> : <h1>Novo Produto</h1>}
      <Widget>
        <Icon>
          <img src={iconDisplay} alt="icon do produto" />

          <label htmlFor="icon">
            <input
              type="file"
              name="icon"
              id="icon"
              accept="image/png, image/jpeg"
              onChange={handleChangeIcon}
            />
            <div>
              <img src={add_photo} alt="icon adicionar" />
            </div>
          </label>
          <p>{iconError}</p>
        </Icon>
        <Form id="add_product" onSubmit={handleSubmit(handleOnSubmit)}>
          <FormLine>
            <Input
              width="60%"
              title="Produto"
              type="text"
              {...register('name')}
              error={errors.name?.message}
            />
            <Controller
              control={control}
              name="marketCategoryId"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    title="Categoria"
                    {...register('marketCategoryId')}
                    onChange={onChange}
                    width="35%"
                    value={value}
                    error={errors.marketCategoryId?.message}
                    options={categories.map((cat) => ({
                      value: cat.id,
                      label: cat.name,
                    }))}
                  />
                );
              }}
            />
          </FormLine>
          <FormLine>
            <Input
              width="80%"
              title="Descrição"
              type="text"
              {...register('description')}
              error={errors.description?.message}
            />

            <Input
              width="15%"
              title="Prateleira"
              textAlign="center"
              type="number"
              {...register('catShelf')}
              error={errors.catShelf?.message}
            />
          </FormLine>
          <FormLine>
            {!product && (
              <Input
                width="20%"
                title="Stock (/un)"
                type="number"
                textAlign="center"
                defaultValue="0"
                {...register('stock')}
                error={errors.stock?.message}
              />
            )}
            <Input
              width={product ? '35%' : '25%'}
              title="Alerta Stock (/un)"
              type="number"
              textAlign="center"
              defaultValue="0"
              {...register('stockAlert')}
              error={errors.stockAlert?.message}
            />

            <Controller
              control={control}
              name="visibleTo"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    title="Visível para"
                    {...register('visibleTo')}
                    onChange={onChange}
                    width={product ? '30%' : '20%'}
                    value={value || '0'}
                    error={errors.marketCategoryId?.message}
                    options={[
                      { value: '0', label: 'Todos' },
                      { value: '1', label: 'Mensal' },
                      { value: '2', label: 'Bimensal' },
                      { value: '4', label: 'Semanal' },
                    ]}
                  />
                );
              }}
            />

            <Input
              width={product ? '25%' : '20%'}
              title="Valor (sm)"
              type="number"
              textAlign="center"
              defaultValue="0"
              step="0.01"
              {...register('value')}
              error={errors.value?.message}
            />
          </FormLine>

          <FormLineBottom>
            <div
              style={{
                marginTop: '-15px',
                marginBottom: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <CheckBox
                width="100%"
                title="visível na etiqueta"
                type="checkbox"
                {...register('visibleOnTicket')}
                error={errors.visibleOnTicket?.message}
              />
              <CheckBox
                width="100%"
                title="produto esgota"
                type="checkbox"
                {...register('stockZeroBlock')}
                error={errors.stockZeroBlock?.message}
              />
            </div>

            <h3>Limites por membros do agregado</h3>
            <Table>
              <thead>
                <tr>
                  <Th width="10%" talign="center">
                    1
                  </Th>
                  <Th width="10%" talign="center">
                    2
                  </Th>
                  <Th width="10%" talign="center">
                    3
                  </Th>
                  <Th width="10%" talign="center">
                    4
                  </Th>
                  <Th width="10%" talign="center">
                    5
                  </Th>
                  <Th width="10%" talign="center">
                    6
                  </Th>
                  <Th width="10%" talign="center">
                    7
                  </Th>
                  <Th width="10%" talign="center">
                    8
                  </Th>
                  <Th width="10%" talign="center">
                    9
                  </Th>
                  <Th width="10%" talign="center">
                    10
                  </Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Th
                    colSpan={10}
                    width="100%"
                    talign="center"
                    fontSize="1.4"
                    pt="0.2"
                    pb="0.2"
                  >
                    Por encomenda
                  </Th>
                </tr>
                <tr>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member1')}
                      error={errors.member1?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member2')}
                      error={errors.member2?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member3')}
                      error={errors.member3?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member4')}
                      error={errors.member4?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member5')}
                      error={errors.member5?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member6')}
                      error={errors.member6?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member7')}
                      error={errors.member7?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member8')}
                      error={errors.member8?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member8')}
                      error={errors.member8?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member9')}
                      error={errors.member9?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('member10')}
                      error={errors.member10?.message}
                    />
                  </Td>
                </tr>
                <tr>
                  <Th
                    colSpan={10}
                    width="100%"
                    talign="center"
                    fontSize="1.4"
                    pt="0.2"
                    pb="0.2"
                  >
                    Por mês
                  </Th>
                </tr>
                <tr>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth1')}
                      error={errors.memberMonth1?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth2')}
                      error={errors.memberMonth2?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth3')}
                      error={errors.memberMonth3?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth4')}
                      error={errors.memberMonth4?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth5')}
                      error={errors.memberMonth5?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth6')}
                      error={errors.memberMonth6?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth7')}
                      error={errors.memberMonth7?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth8')}
                      error={errors.memberMonth8?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth8')}
                      error={errors.memberMonth8?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth9')}
                      error={errors.memberMonth9?.message}
                    />
                  </Td>
                  <Td talign="center" width="10%">
                    <Input
                      width="100%"
                      title=""
                      type="number"
                      textAlign="center"
                      borderSize="0"
                      defaultValue="0"
                      {...register('memberMonth10')}
                      error={errors.memberMonth10?.message}
                    />
                  </Td>
                </tr>
              </tbody>
            </Table>
          </FormLineBottom>
        </Form>
      </Widget>
      <ButtonBox>
        <Button
          type="button"
          color="#007970"
          bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
          bghover="#10d5c2"
          onClick={closeModal}
          disabled={isLoading}
        >
          <img src={chevron} alt="Icon de seta back" />
          Voltar
        </Button>
        {isLoading ? (
          <Button
            form="add_product"
            color="#3cf0de"
            bgcolor={isLoading ? '#00665f' : '#007970'}
            bghover="#00665f"
            disabled={isLoading}
          >
            <img src={loading} alt="Icon de desquete save" />a carregar...
          </Button>
        ) : (
          <Button
            form="add_product"
            type="submit"
            color="#3cf0de"
            bgcolor="#007970"
            bghover="#00665f"
          >
            <img src={save} alt="Icon de desquete save" />
            Guardar
          </Button>
        )}
      </ButtonBox>
    </Container>
  );
};

export default ProductForm;
