/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useState, useCallback } from 'react';

import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
} from 'date-fns';
import { pt } from 'date-fns/locale';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import loading from '../../assets/loading01.svg';

import {
  Container,
  Widget,
  Loading,
  TableOrder,
  Title,
  Top,
  Line,
  Cards,
  Card,
  FormSearch,
  ErrorDate,
} from './styles';
import Th from '../../components/Table/Th';
import { User } from '../../types/Users';
import Td from '../../components/Table/Td';

interface Donations {
  id: string;
  userId: string;
  support_resp: string;
  description: string;
  type: number;
  value: string;
  date: string;
  social_tax: number;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  user: User;
}

const DonationList: React.FC = () => {
  const [donations, setDonations] = useState<Donations[]>([]);
  const [type, setType] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const [totalUser, setTotalUser] = useState(0);
  const [totalSupp, setTotalSupp] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [totalsocialTax, setTotalsocialTax] = useState(0);
  const [dates, setDates] = useState({
    ds: startOfDay(new Date()),
    de: endOfDay(new Date()),
  });
  const [dateError, setDateError] = useState('');

  const { addToast } = useToast();

  const getDonations = useCallback(
    async (t: number | null, s: string | null, ds: string, de: string) => {
      setIsLoading(true);
      try {
        const response = await api.get(
          `/support/donations/filter?type=${t || ''}&search=${
            s || ''
          }&ds=${ds}&de=${de}`,
        );

        setDonations(response.data);

        const tu: User[] = [];

        response.data.forEach((donation: Donations) => {
          const userAlreadyIn = tu.find((u) => u.id === donation.user.id);

          if (!userAlreadyIn) tu.push(donation.user);
        });

        setTotalUser(tu.length);
        setTotalSupp(response.data.length);
        setTotalValue(
          response.data.reduce(
            (acc: number, curr: Donations) =>
              acc + parseFloat(curr.value.replace(',', '.')),
            0,
          ),
        );
        setTotalsocialTax(
          response.data.reduce(
            (acc: number, curr: Donations) => acc + curr.social_tax,
            0,
          ),
        );

        setIsLoading(false);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro a obter doações',
          description: { code: 751 },
        });
      }
    },
    [addToast],
  );

  const handleDateChange = (t: 'ds' | 'de', d: string) => {
    if (t === 'ds') {
      const date = startOfDay(parseISO(d));

      if (isAfter(date, dates.de)) {
        setDateError('Data de inicio não pode ser maior de a data de fim');
        return;
      }

      setDates((prevState) => ({ ...prevState, ds: date }));
    } else if (t === 'de') {
      const date = startOfDay(parseISO(d));

      if (isBefore(date, dates.ds)) {
        setDateError('Data de fim não pode ser menor de a data de inicio');
        return;
      }

      setDates((prevState) => ({ ...prevState, de: date }));
    }

    setDateError('');
  };

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Apoios</h1>
            </Title>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <div>
              <FormSearch>
                <div>
                  <span> Inicio:</span>
                  <input
                    defaultValue={format(dates.ds, 'yyyy-MM-dd')}
                    type="date"
                    onChange={(e) =>
                      e.currentTarget.value &&
                      handleDateChange('ds', e.currentTarget.value)
                    }
                  />
                </div>
                <div>
                  <span> Fim:</span>
                  <input
                    defaultValue={format(dates.de, 'yyyy-MM-dd')}
                    type="date"
                    onChange={(e) =>
                      e.currentTarget.value &&
                      handleDateChange('de', e.currentTarget.value)
                    }
                  />
                </div>
                <div>
                  <span>Pesquisar:</span>
                  <input
                    type="text"
                    name="search"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    defaultValue={search || ''}
                    style={{
                      border: '1px solid rgba(60, 240, 222, 0.75)',
                      borderRadius: '5px',
                    }}
                  />
                </div>
                <div>
                  <span>Tipo de Apoio:</span>

                  <select
                    onChange={(e) => setType(Number(e.currentTarget.value))}
                    defaultValue={type || ''}
                    style={{
                      border: '1px solid rgba(60, 240, 222, 0.75)',
                      borderRadius: '5px',
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="1">Jurídico</option>
                    <option value="2">Emprego</option>
                    <option value="3">Bens</option>
                    <option value="4">Saúde</option>
                    <option value="5">Psicólogo</option>
                    <option value="6">Serviços</option>
                    <option value="7">Subsídios</option>
                  </select>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    getDonations(
                      type,
                      search,
                      format(dates.ds, 'yyyy-MM-dd'),
                      format(dates.de, 'yyyy-MM-dd'),
                    )
                  }
                >
                  Pesquisar
                </button>
              </FormSearch>
              {dateError && <ErrorDate>{dateError}</ErrorDate>}
              <Cards>
                <Card>
                  <h3>Utentes</h3>
                  <h2>{totalUser}</h2>
                </Card>
                <Card>
                  <h3>Total Apoios</h3>
                  <h2>{totalSupp}</h2>
                </Card>
                <Card>
                  <h3>Total Valor</h3>
                  <h2>{totalValue.toFixed(2)}€</h2>
                </Card>
                <Card>
                  <h3>Total Tax Social</h3>
                  <h2>{totalsocialTax.toFixed(2)}€</h2>
                </Card>
              </Cards>
              <TableOrder>
                <table>
                  <thead>
                    <tr>
                      <Th talign="center" width="3%">
                        #
                      </Th>
                      <Th talign="center" width="10%">
                        Ref. Int
                      </Th>
                      <Th talign="left" width="20%">
                        Nome
                      </Th>
                      <Th talign="left" width="11%">
                        Resp
                      </Th>
                      <Th talign="left" width="30%">
                        Descrição
                      </Th>
                      <Th talign="center" width="8%">
                        Valor
                        <p>
                          <small>(€)</small>
                        </p>
                      </Th>
                      <Th talign="center" width="8%">
                        Taxa S.
                        <p>
                          <small>(€)</small>
                        </p>
                      </Th>
                      <Th talign="center" width="10%">
                        Data
                      </Th>
                    </tr>
                  </thead>
                  <tbody>
                    {donations.length > 0 &&
                      donations.map((donation, index) => (
                        <Line key={donation.id}>
                          <Td talign="center" width="3%">
                            {index + 1}
                          </Td>
                          <Td talign="center" width="10%">
                            {donation.user.int_num}
                          </Td>
                          <Td talign="left" width="20%">
                            {donation.user.name}
                          </Td>
                          <Td talign="left" width="11%">
                            {donation.support_resp}
                          </Td>
                          <Td talign="left" width="30%">
                            {donation.description}
                          </Td>
                          <Td talign="center" width="8%">
                            {parseFloat(
                              donation.value.replace(',', '.'),
                            ).toFixed(2)}
                            <small>€</small>
                          </Td>
                          <Td talign="center" width="8%">
                            {donation.social_tax.toFixed(2)}
                            <small>€</small>
                          </Td>
                          <Td talign="center" width="10%">
                            {format(parseISO(donation.date), `dd'/'MM'/'yyyy`, {
                              locale: pt,
                            })}
                          </Td>
                        </Line>
                      ))}
                  </tbody>
                </table>
              </TableOrder>
            </div>
          )}
        </Widget>
      </Container>
    </>
  );
};

export default DonationList;
