import styled from 'styled-components';

type ModalProps = {
  visible: boolean;
};

type ButtonProps = {
  color: string;
  bgcolor: string;
  bghover: string;
};

export const Container = styled.div<ModalProps>`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 60rem;
  max-width: 60rem;
  height: 60rem;
  margin-top: 10rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  padding: 5rem;
  flex-direction: column;
`;

export const Form = styled.form`
  flex: 1;
`;

export const FormLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    align-self: flex-start;
    margin-left: 10px;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  & + & {
    margin-top: 4rem;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.bgcolor};
  min-width: 15rem;
  padding: 0.7rem;
  font-size: 2rem;
  border-radius: 5px;
  border: none;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;
  }

  img {
    width: 2.4rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(props) => props.bghover};
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  margin-top: 1rem;
  margin-right: 1rem;

  button {
    background: transparent;
    border: none;
    font-size: 2.8rem;
    color: #00b3a7;

    &:hover {
      color: #007970;
    }
  }
`;

export const CenterCheckBox = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
