import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  justify-content: center;
`;

export const Widget = styled.div`
  width: 140rem;
  max-width: 140rem;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 15rem;
  }

  p {
    font-size: 2.2rem;
    position: absolute;
    bottom: 1rem;
  }
`;

export const Top = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  button {
    margin-left: 1rem;
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 3rem;
    font-weight: bold;
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  input,
  select {
    border: 2px solid #007970;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 20rem;
    color: #007970;
    font-size: 1.6rem;
    &::-webkit-input-placeholder {
      color: rgba(0, 121, 112, 0.8);
    }
  }

  button {
    margin-left: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const TableOrder = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 50rem;

  box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);
  -moz-box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);
  -webkit-box-shadow: 0px 10px 10px 5px rgba(60, 240, 222, 0.75);

  thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
`;

export const Line = styled.tr`
  background-color: #fff;
`;

export const Cards = styled.div`
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
  display: grid;
  align-content: center;
  justify-items: center;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
`;

export const Card = styled.div`
  display: flex;
  width: 200px;
  height: 100px;
  background: #b8faf3;
  border-radius: 20px;
  position: relative;

  padding: 1.5rem;

  flex: 1;
  display: flex;
  flex-direction: column;

  box-shadow: 5px 5px 5px rgba(60, 240, 222, 0.75);

  h3 {
    font-size: 20px;
  }

  h2 {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-size: 26px;
  }
`;

export const FormSearch = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 18px;
      margin-right: 5px;
    }

    > input,
    select {
      padding: 0.9rem;
      color: #007970;
      font-size: 1.8rem;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      &::-webkit-input-placeholder {
        color: rgba(0, 121, 112, 0.8);
      }
    }
  }

  button {
    width: 10rem;
    padding: 0.5rem;
    border-radius: 10px;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    border: none;
    color: #b8faf3;

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const ErrorDate = styled.p`
  color: #ff5050;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;
